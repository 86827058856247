import axios from 'axios'
// 某些內容需要authentication header才能取得
import authHeader from './auth-header'
import Config from '@/config/config'
const API_URL = Config.basicBackendBaseURL
const APP_API_URL = Config.appBackendBaseURL

class UserService {
  addAppRecordToBufferDB () {
    // console.log('Add app content into buffer db ...')
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    return axios.get(API_URL + 'service/add-records', { headers: headerConfig })
  }

  getRecordInExcel () {
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/octet-stream'
    // axios的response type設置成blob否則excel的檔案送來二進邊碼會自動編譯成json導致錯誤無法開啟(axios針對二進檔案內定是處理成json)
    return axios.get(API_URL + 'service/get-records-in-excel', { headers: headerConfig, responseType: 'blob' })
  }

  getRecordByProductInExcel (product) {
    const dataForm = new FormData()
    dataForm.append('product', product)
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/octet-stream'
    return axios.post(API_URL + 'service/get-records-in-excel-by-product', dataForm, { headers: headerConfig, responseType: 'blob' })
  }

  getRecordInExcelWithGPT (content) {
    const dataForm = new FormData()
    dataForm.append('content', content)
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/octet-stream'
    // axios的response type設置成blob否則excel的檔案送來二進邊碼會自動編譯成json導致錯誤無法開啟(axios針對二進檔案內定是處理成json)
    return axios.post(API_URL + 'service/get-records-in-excel-user-text-by-gpt', dataForm, { headers: headerConfig, responseType: 'blob' })
  }

  getAuthRecordAmount () {
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    return axios.get(API_URL + 'service/get-records-amount', { headers: headerConfig })
  }

  getAuthRecordContentByNumber (n) {
    const dataForm = new FormData()
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    if (isNaN(n) || n < 0 || n === '') {
      n = 0
      // console.log(n)
      dataForm.append('number', n)
      return axios.post(API_URL + 'service/get-records-by-offset', dataForm, { headers: headerConfig })
    } else {
      n = Math.trunc(n)
      // console.log(n - 1)
      dataForm.append('number', n)
      return axios.post(API_URL + 'service/get-records-by-offset', dataForm, { headers: headerConfig })
    }
  }

  getAuthRecordContent (type) {
    // 設置三種條件0(指定的最新時間紀錄-後端指定數量)、1(指定的最末ID紀錄-後端指定數量)、2(未驗證上鏈紀錄-後端指定數量)
    // 0 API: /amount-unix-blockchain-records-uuid
    // 1 API: /amount-id-blockchain-records-uuid
    // 2 API: /all-unverified-blockchain-records-uuid
    // console.log('Get auth content ...')
    if (type === 0) {
      const headerConfig = authHeader()
      headerConfig.Accept = 'application/json'
      return axios.get(API_URL + 'blockchain/amount-unix-blockchain-records-uuid', { headers: headerConfig })
    } else if (type === 1) {
      const headerConfig = authHeader()
      headerConfig.Accept = 'application/json'
      return axios.get(API_URL + 'blockchain/amount-id-blockchain-records-uuid', { headers: headerConfig })
    } else if (type === 2) {
      const headerConfig = authHeader()
      headerConfig.Accept = 'application/json'
      return axios.get(API_URL + 'blockchain/all-unverified-blockchain-records-uuid', { headers: headerConfig })
    } else {
      // 其他情況，譬如空值 or other exceptions則都是unix時間列出指定數量的records
      const headerConfig = authHeader()
      headerConfig.Accept = 'application/json'
      return axios.get(API_URL + 'blockchain/amount-unix-blockchain-records-uuid', { headers: headerConfig })
    }
    // 下面這段是全部record取得的source code
    // const headerConfig = authHeader()
    // headerConfig.Accept = 'application/json'
    // return axios.get(API_URL + 'blockchain/all-blockchain-records-uuid', { headers: headerConfig })
    // ------------------------------------------------------------------------------------------------
    // const headerContent = { Accept: 'application/json' }
    // return axios.get(API_URL + 'service/all-records', { headers: headerContent })
  }

  checkAuthRecordContent () {
    // console.log('Get auth content ...')
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    return axios.get(API_URL + 'blockchain/recheck-record-verified', { headers: headerConfig })

    // const headerContent = { Accept: 'application/json' }
    // return axios.get(API_URL + 'service/all-records', { headers: headerContent })
  }

  // Hyperledger Fabric network connection checking
  checkConnection () {
    // console.log('Check blockchain connection ...')
    const headerContent = { Accept: 'application/json' }
    return axios.get(API_URL + 'blockchain/connection', { headers: headerContent })
  }

  // App backend connection checking
  checkAppConnection () {
    // console.log('Check blockchain connection ...')
    const headerContent = { Accept: 'application/json' }
    return axios.get(API_URL + 'service/app-connection', { headers: headerContent })
  }

  switchErrorTag (recordId) {
    // console.log('Switch blockchain err tag ...')
    const dataForm = new FormData()
    dataForm.append('record_id', recordId)
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    return axios.post(API_URL + 'blockchain/switch-record-err-tag', dataForm, { headers: headerConfig })
  }

  GenerateNewNode (product) {
    // console.log('Generate node ...')
    const dataForm = new FormData()
    dataForm.append('product', product)
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    return axios.post(API_URL + 'graph/generate-nodes', dataForm, { headers: headerConfig })
  }

  GenerateNewGraph (product) {
    // console.log('Generate graph structure and style ...')
    const dataForm = new FormData()
    dataForm.append('product', product)
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    return axios.post(API_URL + 'graph/generate-graph-structure-style', dataForm, { headers: headerConfig })
  }

  GetRecordNoteImgByID (recordId) {
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    const dataForm = new FormData()
    dataForm.append('id', recordId)
    return axios.post(API_URL + 'service/get-record-note-image', dataForm, { headers: headerConfig })
  }

  GeneralCarbonCompute (method, product, place) {
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    const dataForm = new FormData()
    dataForm.append('method', method)
    dataForm.append('product', product)
    dataForm.append('place', place)
    return axios.post(API_URL + 'service/general-carbon-compute', dataForm, { headers: headerConfig })
  }

  UploadCarbonParametersExcel (selectedFile) {
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    const formData = new FormData()
    formData.append('file', selectedFile)
    return axios.post(API_URL + 'service/upload-parameters-to-bufferdb', formData, { headers: headerConfig })
  }

  GetCarbonParametersByProductInExcel (product) {
    const dataForm = new FormData()
    dataForm.append('product', product)
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/octet-stream'
    return axios.post(API_URL + 'service/get-carbon-parameters-in-excel', dataForm, { headers: headerConfig, responseType: 'blob' })
  }

  GetCarbonComputedByProductMethodInExcel (method, product, place) {
    const dataForm = new FormData()
    dataForm.append('product', product)
    dataForm.append('method', method)
    dataForm.append('place', place)
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/octet-stream'
    return axios.post(API_URL + 'service/general-carbon-compute-in-excel', dataForm, { headers: headerConfig, responseType: 'blob' })
  }

  GetRecordFromApp () {
    // console.log('Get Records From App ...')
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    return axios.get(API_URL + 'service/add-records', { headers: headerConfig })
  }

  SendBufferRecordToBlockchain () {
    // console.log('Verify Buffer Records To Blockchain ...')
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    return axios.get(API_URL + 'blockchain/add-all-records', { headers: headerConfig })
  }

  CheckAddBlockchainStatus () {
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    return axios.get(API_URL + 'blockchain/check-add-blockchain-status', { headers: headerConfig })
  }

  RecheckRecordVerified () {
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    return axios.get(API_URL + 'blockchain/recheck-record-verified-by-ids', { headers: headerConfig })
  }

  UploadExcel (selectedFile) {
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    const formData = new FormData()
    formData.append('file', selectedFile)
    return axios.post(API_URL + 'service/upload-excel-to-bufferdb', formData, { headers: headerConfig })
  }

  UploadNoteJpgImg (selectedFile, id) {
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    const formData = new FormData()
    formData.append('file', selectedFile)
    formData.append('id', id)
    return axios.post(API_URL + 'service/upload-record-image', formData, { headers: headerConfig })
  }

  DeleteNoteJpgImgByRecordId (id) {
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    const formData = new FormData()
    formData.append('id', id)
    return axios.post(API_URL + 'service/delete-record-image', formData, { headers: headerConfig })
  }

  UploadNoteTextContent (id, note) {
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    const formData = new FormData()
    formData.append('id', id)
    formData.append('note', note)
    return axios.post(API_URL + 'service/upload-record-note', formData, { headers: headerConfig })
  }

  DeleteNoteTextContent (id) {
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    const formData = new FormData()
    formData.append('id', id)
    return axios.post(API_URL + 'service/delete-record-note', formData, { headers: headerConfig })
  }

  GetProductList () {
    const productListRequest = axios.create({
      baseURL: Config.basicBackendBaseURL,
      // Accept: 'application/json' 表示要接收的是JSON
      headers: { Accept: 'application/json' }
    })
    return productListRequest.get('/graph/product-list')
  }

  GetDetailRecordByProductWithAuth (product) {
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    headerConfig['Content-Type'] = 'multipart/form-data'
    const formData = new FormData()
    formData.append('product', product)
    return axios.post(API_URL + 'service/all-records-by-product-with-auth', formData, { headers: headerConfig })
  }

  // 下面內容是配合app auth的相關App服務
  GetActionDefineInExcel () {
    const userAppBearerToken = JSON.parse(localStorage.getItem('user-app'))
    const headerConfig = {
      Authorization: userAppBearerToken,
      Accept: 'application/octet-stream'
    }
    // console.log(headerConfig.Authorization)
    headerConfig['Content-Type'] = 'multipart/form-data; boundary=WebAppBoundary'
    // axios的response type設置成blob否則excel的檔案送來二進邊碼會自動編譯成json導致錯誤無法開啟(axios針對二進檔案內定是處理成json)
    return axios.get(APP_API_URL + 'api/action-excel', { headers: headerConfig, responseType: 'blob' })
  }

  UploadActionDefineInExcel (selectedFile) {
    const userAppBearerToken = JSON.parse(localStorage.getItem('user-app'))
    const headerConfig = {
      Authorization: userAppBearerToken,
      Accept: 'application/json'
    }
    headerConfig['Content-Type'] = 'multipart/form-data'
    const formData = new FormData()
    formData.append('file', selectedFile)
    return axios.post(APP_API_URL + 'api/upload-action-excel', formData, { headers: headerConfig })
  }

  GetActionSuperMapFromApp () {
    const userAppBearerToken = JSON.parse(localStorage.getItem('user-app'))
    const headerConfig = {
      Authorization: userAppBearerToken,
      Accept: 'application/json'
    }
    // console.log(headerConfig.Authorization)
    // axios的response type設置成blob否則excel的檔案送來二進邊碼會自動編譯成json導致錯誤無法開啟(axios針對二進檔案內定是處理成json)
    return axios.get(APP_API_URL + 'api/action-super-map', { headers: headerConfig })
  }

  HardDeleteNormalAction (id) {
    const userAppBearerToken = JSON.parse(localStorage.getItem('user-app'))
    const headerConfig = {
      Authorization: userAppBearerToken,
      Accept: 'application/json'
    }
    headerConfig['Content-Type'] = 'application/json; charset=utf-8'
    return axios.delete(`${APP_API_URL}api/hard-delete-action/${id}`, {
      headers: headerConfig
    })
  }

  SendSuperInfoToAppBackendToBuildTask (name, actionSet, content) {
    const userAppBearerToken = JSON.parse(localStorage.getItem('user-app'))
    const headerConfig = {
      Authorization: userAppBearerToken,
      Accept: 'application/json'
    }
    const dataForm = new FormData()
    headerConfig['Content-Type'] = 'multipart/form-data; boundary=WebAppBoundary'
    const jsonActionSetString = JSON.stringify(actionSet)
    dataForm.append('name', name)
    dataForm.append('action_set', jsonActionSetString)
    dataForm.append('content', content)
    return axios.post(APP_API_URL + 'api/super-action', dataForm, { headers: headerConfig })
  }

  SendSuperInfoToAppBackendToBuildTaskAtUpdateButton (superActionId, name, actionSet, content) {
    const userAppBearerToken = JSON.parse(localStorage.getItem('user-app'))
    const headerConfig = {
      Authorization: userAppBearerToken,
      Accept: 'application/json'
    }
    const dataForm = new FormData()
    headerConfig['Content-Type'] = 'multipart/form-data; boundary=WebAppBoundary'
    const jsonActionSetString = JSON.stringify(actionSet)
    dataForm.append('super_action_id', superActionId)
    dataForm.append('name', name)
    dataForm.append('action_set', jsonActionSetString)
    dataForm.append('content', content)
    return axios.put(APP_API_URL + 'api/super-action', dataForm, { headers: headerConfig })
  }

  HardDeleteSuperInfoToAppBackendToBuildTask (superActionId) {
    const userAppBearerToken = JSON.parse(localStorage.getItem('user-app'))
    const headerConfig = {
      Authorization: userAppBearerToken,
      Accept: 'application/json'
    }
    headerConfig['Content-Type'] = 'application/json; charset=utf-8'
    return axios.delete(`${APP_API_URL}api/hard-delete-super-action/${superActionId}`, {
      headers: headerConfig
    })
  }
}
export default new UserService()

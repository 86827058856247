export default function () {
  // 取出JWT token，這存放例子的key為user
  const user = JSON.parse(localStorage.getItem('user'))

  // 檢視是否user有內容，並有accessToken
  if (user && user.accessToken) {
    // // console.log(user.accessToken)
    // 產生授權header
    return { Authorization: user.accessToken }
  } else {
    return {}
  }
}
